import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroBanner from "../components/hero-banner";
import Content, { HTMLContent } from "../components/Content";

import svgEmployee from "../images/svg/user-type-dark-icon.svg";
import svgLocation from "../images/svg/location-dark-icon.svg";
import svgJobType from "../images/svg/services-dark-icon.svg";
import svgExperience from "../images/svg/portfolio-dark-icon.svg";
import svgSalary from "../images/svg/bag-with-money-dark-icon.svg";
import svgDate from "../images/svg/calendar-dark-icon.svg";

export const CareersPostTemplate = ({
  content,
  contentComponent,
  employeeType,
  title,
  date,
  jobType,
  location,
  experience,
  salary,
  desktopWide,
  desktop,
  tablet,
  mobile,
  mobileXS
}) => {
  const PostContent = contentComponent || Content;

  return (
    <Fragment>
      <div className="gradient-half-blue-v1">
        <HeroBanner
          desktopWide={desktopWide}
          desktop={desktop}
          tablet={tablet}
          mobile={mobile}
          mobileXS={mobileXS}
        >
          <h1 className="text-white"> {title}</h1>
          <p className="d-block text-white">at {location}</p>
        </HeroBanner>
      </div>

      <div className="container space-1 space-2--lg">
        <div className="row">
          <div className="col-lg-3 order-lg-2 mb-7 mb-lg-0">
            <div className="mb-4">
              <h2 className="h4">Job snapshot</h2>
            </div>

            <ul className="list-unstyled">
              <li className="media align-items-center pb-2">
                <img
                  className="max-width-6 mr-3"
                  src={svgEmployee}
                  alt="Employee type"
                />
                <div className="media-body">
                  <h3 className="h6 mb-0">Employee type:</h3>
                  <small className="text-secondary">{employeeType}</small>
                </div>
              </li>

              <li className="dropdown-divider"></li>

              <li className="media align-items-center pb-2">
                <img
                  className="max-width-6 mr-3"
                  src={svgLocation}
                  alt="Location"
                />
                <div className="media-body">
                  <h3 className="h6 mb-0">Location:</h3>
                  <small className="text-secondary">{location}</small>
                </div>
              </li>

              <li className="dropdown-divider"></li>

              <li className="media align-items-center pb-2">
                <img
                  className="max-width-6 mr-3"
                  src={svgJobType}
                  alt="Job type"
                />
                <div className="media-body">
                  <h3 className="h6 mb-0">Job type:</h3>
                  <small className="text-secondary">{jobType}</small>
                </div>
              </li>

              <li className="dropdown-divider"></li>

              <li className="media align-items-center pb-2">
                <img
                  className="max-width-6 mr-3"
                  src={svgExperience}
                  alt="Experience"
                />
                <div className="media-body">
                  <h3 className="h6 mb-0">Experience:</h3>
                  <small className="text-secondary">
                    {experience ? experience : "Not specified"}
                  </small>
                </div>
              </li>

              <li className="dropdown-divider"></li>

              <li className="media align-items-center pb-2">
                <img
                  className="max-width-6 mr-3"
                  src={svgSalary}
                  alt="Salary"
                />
                <div className="media-body">
                  <h3 className="h6 mb-0">Salary:</h3>
                  <small className="text-secondary">
                    {salary ? salary : "Not specified"}
                  </small>
                </div>
              </li>

              <li className="dropdown-divider"></li>

              <li className="media align-items-center pb-2">
                <img
                  className="max-width-6 mr-3"
                  src={svgDate}
                  alt="Date posted"
                />
                <div className="media-body">
                  <h3 className="h6 mb-0">Date posted:</h3>
                  <small className="text-secondary">{date}</small>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-9 order-lg-1">
            <div className="pr-lg-4">
              <div className="mb-4">
                <h2 className="h2">Job description</h2>
              </div>

              <PostContent content={content} />
              <p>
                <Link to="/careers/">Back to Careers</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const CareersPost = ({ data }) => {
  const { kdccmsCareers: post } = data;

  return (
    <Layout>
      <SEO
        title={`${post.title} | Careers`}
        keywords={[
          "careers",
          "tags",
          "electrical",
          "Philippines",
          "design",
          "build",
          "system",
          "supply",
          "troubleshoot",
          "repair",
          "equipments",
          "test",
          "manage",
          "bms"
        ]}
        description={`${post.jobType}`}
      />
      <CareersPostTemplate
        content={post.body}
        contentComponent={HTMLContent}
        title={post.title}
        jobType={post.jobType}
        employeeType={post.employeeType}
        date={post.publishDate}
        location={post.location}
        experience={post.experience}
        salary={post.salary}
        desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
        desktop={data.bgDesktop.childImageSharp.fluid.src}
        tablet={data.bgTablet.childImageSharp.fluid.src}
        mobile={data.bgMobile.childImageSharp.fluid.src}
        mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
      />
    </Layout>
  );
};

export default CareersPost;

export const pageQuery = graphql`
  query CareersPostByID($id: String!) {
    kdccmsCareers(id: { eq: $id }) {
      id
      publishDate(formatString: "MMMM DD, YYYY")
      title
      employeeType
      jobType
      location
      experience
      body
    }
    bgDesktopWide: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
